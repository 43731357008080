// Generated by Framer (c163575)

import { addFonts, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-IXaWP"

const variantClassNames = {wimqHGOOH: "framer-v-a2idmf"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "wimqHGOOH", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-a2idmf", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"wimqHGOOH"} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", ...style}}><motion.div className={"framer-d55qt0"} layoutDependency={layoutDependency} layoutId={"Wb_j4KiKk"} style={{rotateY: 63}}><Image background={{alt: "", fit: "fill", intrinsicHeight: 920, intrinsicWidth: 1912, loading: getLoadingLazyAtYPosition(((((componentViewport?.y || 0) + (componentViewport?.height || 396)) - 368) + 0)), pixelHeight: 920, pixelWidth: 1912, sizes: `max(${componentViewport?.width || "100vw"} + 1857px, 1px)`, src: "https://framerusercontent.com/images/tBRI1vCF9xpRObwPolSCEwyYc.png", srcSet: "https://framerusercontent.com/images/tBRI1vCF9xpRObwPolSCEwyYc.png?scale-down-to=512 512w,https://framerusercontent.com/images/tBRI1vCF9xpRObwPolSCEwyYc.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/tBRI1vCF9xpRObwPolSCEwyYc.png 1912w"}} className={"framer-1rdyg6x"} data-framer-name={"Www.krauss-gmbh.com  (1)"} layoutDependency={layoutDependency} layoutId={"JMoJiLQTJ"}/></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-IXaWP.framer-1l6ilao, .framer-IXaWP .framer-1l6ilao { display: block; }", ".framer-IXaWP.framer-a2idmf { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 396px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 1583px; }", ".framer-IXaWP .framer-d55qt0 { align-content: center; align-items: center; bottom: -584px; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 952px; justify-content: center; left: -1017px; overflow: visible; padding: 0px; position: absolute; right: -840px; z-index: 1; }", ".framer-IXaWP .framer-1rdyg6x { flex: 1 0 0px; height: 100%; overflow: visible; position: relative; width: 1px; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-IXaWP.framer-a2idmf, .framer-IXaWP .framer-d55qt0 { gap: 0px; } .framer-IXaWP.framer-a2idmf > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-IXaWP.framer-a2idmf > :first-child { margin-top: 0px; } .framer-IXaWP.framer-a2idmf > :last-child { margin-bottom: 0px; } .framer-IXaWP .framer-d55qt0 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-IXaWP .framer-d55qt0 > :first-child { margin-left: 0px; } .framer-IXaWP .framer-d55qt0 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 396
 * @framerIntrinsicWidth 1583
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerQdlTKCMmT: React.ComponentType<Props> = withCSS(Component, css, "framer-IXaWP") as typeof Component;
export default FramerQdlTKCMmT;

FramerQdlTKCMmT.displayName = "Test";

FramerQdlTKCMmT.defaultProps = {height: 396, width: 1583};

addFonts(FramerQdlTKCMmT, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})